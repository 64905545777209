<template>
    <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
        <notifications></notifications>
        <sidebar-fixed-toggle-button />
        <side-bar
            :background-color="sidebarBackground"
            :short-title="$t('sidebar.shortTitle')"
            :title="$t('sidebar.title')"
        >
            <template slot-scope="props" slot="links">
                <!--dashboard-->
                <sidebar-item
                    :link="{
                        name: $t('sidebar.dashboard'),
                        icon: 'tim-icons icon-chart-pie-36',
                        path: '/dashboard'
                    }"
                >
                </sidebar-item>
                <!--/dashboard-->

                <!-- my trip -->
                <sidebar-item
                    :link="{
                        name: $t('sidebar.mytrip'),
                        icon: 'tim-icons icon-bus-front-12'
                    }"
                    v-hasPermission="`see my trip`"
                >
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.mytripDashboard'),
                            path: '/mytrip/Dashboard'
                        }"
                        v-hasPermission="`mytrip_dashboard`"
                    ></sidebar-item>

                    <sidebar-item
                        :link="{
                            name: $t('sidebar.mytripThings'),
                            path: '/mytrip/Things'
                        }"
                        v-hasPermission="`mytrip_things`"
                    ></sidebar-item>

                    <sidebar-item
                        :link="{
                            name: $t('sidebar.mytripAgency'),
                            path: '/mytrip/Agency'
                        }"
                        v-hasPermission="`mytrip_agency`"
                    ></sidebar-item>
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.mytripOperator'),
                            path: '/mytrip/Operator'
                        }"
                        v-hasPermission="`mytrip_operator`"
                    ></sidebar-item>
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.mytripEmail'),
                            path: '/mytrip/Email'
                        }"
                        v-hasPermission="`mytrip_email`"
                    ></sidebar-item>
                </sidebar-item>
                <!-- /my trip -->

                <!--visa-->
                <sidebar-item
                    :link="{
                        name: $t('sidebar.visa'),
                        icon: 'tim-icons icon-credit-card'
                    }"
                    v-hasPermission="`visa`"
                >
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.visaDashboard'),
                            path: '/visa'
                        }"
                    ></sidebar-item>
                </sidebar-item>
                <!--/visa-->

                <!--hotels-->
                <sidebar-item
                    :link="{
                        name: $t('sidebar.hotel'),
                        icon: 'tim-icons icon-credit-card'
                    }"
                    v-hasPermission="`Hotel`"
                >
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.hotelDashboard'),
                            path: '/hotels'
                        }"
                    ></sidebar-item>
                </sidebar-item>
                <!--/hotels-->

                <!-- transfer -->
                <sidebar-item
                    :link="{
                        name: $t('sidebar.Transfer'),
                        icon: 'tim-icons icon-square-pin'
                    }"
                    v-has-permission="`see transfer`"
                >
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.dashboard'),
                            path: '/transfer'
                        }"
                        v-has-permission="`transfer_dashboard`"
                    ></sidebar-item>
                </sidebar-item>
                <!-- /transfer -->

                <!-- Flight Section -->
                <sidebar-item
                    :link="{
                        name: $t('sidebar.flight'),
                        icon: 'tim-icons icon-world'
                    }"
                    v-has-permission="`see flight`"
                >
                    <!-- Booked Flights -->
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.flightBookedIndex'),
                            path: '/flight/booked/index'
                        }"
                        v-has-permission="`see flight`"
                    ></sidebar-item>
                    <!-- /Booked Flights -->
                    <!-- Reserved Flights -->
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.flightReservedIndex'),
                            path: '/flight/reserved/index'
                        }"
                        v-has-permission="`see flight`"
                    ></sidebar-item>
                    <!-- /Reserved Flights -->
                    <!-- Users Geo -->
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.flightGeoIndex'),
                            path: '/flight/geo/index'
                        }"
                        v-has-permission="`see flight`"
                    ></sidebar-item>
                    <!-- /Users Geo -->
                    <!-- Stats -->
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.flightStatsIndex'),
                            path: '/flight/stats/index'
                        }"
                        v-has-permission="`see flight`"
                    ></sidebar-item>
                    <!-- /Stats -->
                    <!-- Agents -->
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.flightAgentsIndex'),
                            path: '/flight/agents/index'
                        }"
                        v-has-permission="`see flight`"
                    ></sidebar-item>
                    <!-- /Agents -->
                    <!-- Discount Codes -->
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.flightDiscountCodesIndex'),
                            path: '/flight/discount-codes/index'
                        }"
                        v-has-permission="`see flight`"
                    ></sidebar-item>
                    <!-- /Discount Codes -->
                    <!-- UTM -->
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.flightUTMIndex'),
                            path: '/flight/utm/index'
                        }"
                        v-has-permission="`see flight`"
                    ></sidebar-item>
                    <!-- /UTM -->
                </sidebar-item>
                <!-- /Flight Section -->

                <!-- hotelRequest -->
                <sidebar-item
                    :link="{
                        name: $t('sidebar.hotelRequest'),
                        icon: 'tim-icons icon-badge'
                    }"
                    v-has-permission="`hotelRequest`"
                >
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.hotelRequest'),
                            path: '/hotelRequest/dashboard'
                        }"
                        v-has-permission="`hotelRequest`"
                    ></sidebar-item>

                    <sidebar-item
                        :link="{
                            name: $t('sidebar.hotelRequestForm'),
                            path: '/hotelRequest/form'
                        }"
                        v-has-permission="`hotelRequest`"
                    ></sidebar-item>
                </sidebar-item>
                <!-- /hotelRequest -->

                <!-- Form -->
                <sidebar-item
                    :link="{
                        name: $t('sidebar.form'),
                        icon: 'tim-icons icon-badge'
                    }"
                    v-has-permission="`Form`"
                >
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.travelInsurance'),
                            path: '/form/travel-insurance'
                        }"
                        v-has-permission="`Form`"
                    ></sidebar-item>

                    <sidebar-item
                        :link="{
                            name: $t('sidebar.hotelRegistration'),
                            path: '/form/hotel-registration'
                        }"
                        v-has-permission="`Form`"
                    ></sidebar-item>
                </sidebar-item>
                <!-- /Form -->

                <!-- Wordpress -->
                <sidebar-item
                    :link="{
                        name: $t('sidebar.wordpress'),
                        icon: 'tim-icons icon-attach-87'
                    }"
                    v-has-permission="`wordpress`"
                >
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.wordpressFaq'),
                            path: '/wordpress/faq'
                        }"
                        v-has-permission="`wordpress`"
                    ></sidebar-item>

                    <sidebar-item
                        :link="{
                            name: $t('sidebar.wordpressSearchLocation'),
                            path: '/wordpress/hotel-search-location'
                        }"
                        v-has-permission="`wordpress`"
                    ></sidebar-item>

                    <sidebar-item
                        :link="{
                            name: $t('sidebar.wordpressAds'),
                            path: '/wordpress/ads'
                        }"
                        v-has-permission="`wordpress`"
                    ></sidebar-item>

                    <sidebar-item
                        :link="{
                            name: $t('sidebar.wordpressHotelRoom'),
                            path: '/wordpress/hotel-rooms'
                        }"
                        v-has-permission="`wordpress`"
                    ></sidebar-item>

                    <sidebar-item
                        :link="{
                            name: $t('sidebar.wordpressHotelVerifiedReview'),
                            path: '/wordpress/hotel-verified-reviews'
                        }"
                        v-has-permission="`wordpress`"
                    ></sidebar-item>
                </sidebar-item>
                <!-- /Wordpress -->

                <!-- review -->
                <sidebar-item
                    :link="{
                        name: $t('sidebar.reviews'),
                        icon: 'tim-icons icon-notes'
                    }"
                    v-has-permission="`review`"
                >
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.review'),
                            path: '/reviews/dashboard'
                        }"
                        v-has-permission="`review`"
                    ></sidebar-item>
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.answer'),
                            path: '/reviews/answer'
                        }"
                        v-has-permission="`review`"
                    ></sidebar-item>
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.reviewimportfile'),
                            path: '/reviews/import'
                        }"
                        v-has-permission="`review`"
                    ></sidebar-item>
                </sidebar-item>
                <!-- /review -->

                <!-- hashtag -->
                <sidebar-item
                    :link="{
                        name: $t('sidebar.hashtag'),
                        icon: 'tim-icons icon-tag'
                    }"
                    v-has-permission="`hashtag`"
                >
                    <sidebar-item
                        :link="{
                            name: $t('sidebar.hashtag'),
                            path: '/hashtag/dashboard'
                        }"
                        v-has-permission="`hashtag`"
                    ></sidebar-item>
                </sidebar-item>
                <!-- /hashtag -->

                <sidebar-item
                    :link="{
                        name: $t('sidebar.user'),
                        icon: 'tim-icons icon-single-02'
                    }"
                    v-has-permission="`users`"
                >
                    <sidebar-item
                        :link="{ name: $t('sidebar.users'), path: '/user' }"
                        v-has-permission="`users_table`"
                    ></sidebar-item>
                </sidebar-item>
            </template>
        </side-bar>
        <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
        <sidebar-share :background-color.sync="sidebarBackground">
        </sidebar-share>
        <div class="main-panel" :data="sidebarBackground">
            <dashboard-navbar></dashboard-navbar>
            <router-view name="header"></router-view>

            <div
                :class="{ content: !$route.meta.hideContent }"
                @click="toggleSidebar"
            >
                <zoom-center-transition :duration="200" mode="out-in">
                    <!-- your content here -->
                    <router-view></router-view>
                </zoom-center-transition>
            </div>
            <content-footer v-if="!$route.meta.hideFooter"></content-footer>
        </div>
    </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import SidebarShare from "./SidebarSharePlugin";
function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import SidebarFixedToggleButton from "./SidebarFixedToggleButton.vue";
import { SlideYDownTransition, ZoomCenterTransition } from "vue2-transitions";

export default {
    components: {
        DashboardNavbar,
        ContentFooter,
        SidebarFixedToggleButton,
        DashboardContent,
        SlideYDownTransition,
        ZoomCenterTransition,
        SidebarShare
    },
    data() {
        return {
            sidebarBackground: "blue" //vue|blue|orange|green|red|primary
        };
    },
    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        initScrollbar() {
            let docClasses = document.body.classList;
            let isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                // if we are on windows OS we activate the perfectScrollbar function
                initScrollbar("sidebar");
                initScrollbar("main-panel");
                initScrollbar("sidebar-wrapper");

                docClasses.add("perfect-scrollbar-on");
            } else {
                docClasses.add("perfect-scrollbar-off");
            }
        }
    },
    mounted() {
        this.initScrollbar();
    }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    to {
        opacity: 1;
    }
}

.main-panel .zoomIn {
    animation-name: zoomIn95;
}

@keyframes zoomOut95 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
}

.main-panel .zoomOut {
    animation-name: zoomOut95;
}
</style>
