import DashboardLayout from "src/pages/Layout/DashboardLayout.vue";
import AuthLayout from "src/pages/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
// Dashboard pages
const Dashboard = () =>
    import (
        /* webpackChunkName: "dashboard" */
        "src/pages/Dashboard/Dashboard.vue"
    );
// Pages
const Login = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Pages/Login.vue");
const Register = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Pages/Register.vue");
const Lock = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Pages/Lock.vue");

const MytripDashboard = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Mytrip/Dashboard/Dashboard.vue"
    );
const MytripDashboardInformation = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Mytrip/Dashboard/Information.vue"
    );

const MytripThing = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Mytrip/Thing/Index.vue");
const MytripThingCreate = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Mytrip/Thing/Create.vue");
const MytripThingEdit = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Mytrip/Thing/Edit.vue");

const MytripAgency = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Mytrip/Agency/Index.vue");
const MytripAgencyCreate = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Mytrip/Agency/Create.vue"
    );
const MytripAgencyEdit = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Mytrip/Agency/Edit.vue");

const MytripOperator = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Mytrip/Operator/Index.vue"
    );
const MytripOperatorCreate = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Mytrip/Operator/Create.vue"
    );
const MytripOperatorEdit = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Mytrip/Operator/Edit.vue"
    );

const MytripEmail = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Mytrip/Email/Index.vue");

const Visa = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Visa/Submissions.vue");

const WordpressFAQ = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Wordpress/FAQ.vue");

const WordpressAds = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Wordpress/Ads.vue");

const WordpressHotelRooms = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Wordpress/Hotel/HotelRooms.vue"
    );

const WordpressHotelVerifiedReview = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Wordpress/Hotel/HotelVerifiedReview.vue"
    );

const WordpressSearchLocation = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Wordpress/WordpressSearchLocation.vue"
    );

const Transfer = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Transfer/Dashboard.vue");

const TransferInformation = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Transfer/Information.vue"
    );

const Users = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/User/Dashboard.vue");

// ##############  Flight  ##############
const FlightBookedIndex = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Flight/Booked/index.vue");
const FlightReservedIndex = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Flight/Reserved/index.vue"
    );
const FlightGeoIndex = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Flight/Geo/index.vue");
const FlightStatsIndex = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Flight/Stats/index.vue");
const FlightAgentsIndex = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Flight/Agents/index.vue");
const FlightDiscountCodesIndex = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Flight/Discount_Codes/index.vue"
    );
const FlightTravelersInfoIndex = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Flight/Travelers_Info/index.vue"
    );
const UTMIndex = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Flight/UTM/index.vue");
// ##############  /Flight  ##############

// ##############  /hotelRequest  ##############
const hotelRequestDashboard = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/hotelRequest/Dashboard.vue"
    );
const hotelRequestForm = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/hotelRequest/Form.vue");
// ##############  /hotelRequest  ##############

// ##############  /form  ##############
const TravelInsuranceForm = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Form/HotelRegistration/index.vue"
    );
const HotelRegistrationForm = () =>
    import (
        /* webpackChunkName: "pages" */
        "src/pages/Form/TravelInsurance/index.vue"
    );
// ##############  /form  ##############

// ##############  /form  ##############
const HotelsForm = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Hotel/Hotels/Index.vue");
const HotelsCreateForm = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Hotel/Hotels/Create.vue");
const HotelsEditForm = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Hotel/Hotels/Edit.vue");
// ##############  /form  ##############

// ##############  /review ##############
const ReviewForm = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Review/Dashboard.vue");
const AnswerReviewForm = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Review/answerTable.vue");

const InfoHotelReviewForm = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Review/List/HotelInformation.vue");

const ImportFileReviewForm = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Review/ImportFile.vue");
const AnswerToReview = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Review/answerReviews.vue");
// ##############  /review ##############

// ##############  /Hashtag ##############
const HashtagForm = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Hashtag/Dashboard.vue");
const HashtagUpdate = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Hashtag/Update.vue");
const HashtagStore = () =>
    import ( /* webpackChunkName: "pages" */ "src/pages/Hashtag/Store.vue");
// ##############  /Hashtag ##############


let authPages = {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    children: [{
            path: "/login",
            name: "Login",
            component: Login,
            meta: {
                permission: "login"
            }
        },
        {
            path: "/register",
            name: "Register",
            component: Register
        },
        {
            path: "/lock",
            name: "Lock",
            component: Lock,
            meta: {
                permission: "Lock screen"
            }
        }
    ]
};

let mytrip = {
    path: "/mytrip",
    redirect: "/mytrip/Dashboard",
    component: DashboardLayout,
    name: "Mytrip layout",
    children: [{
            path: "Dashboard",
            name: "dashboard",
            components: {
                default: MytripDashboard
            },
            meta: {
                permission: "see my trip"
            }
        },
        {
            path: "information",
            name: "information",
            components: {
                default: MytripDashboardInformation
            },
            meta: {
                permission: "mytrip_formPersonDetails"
            }
        },
        {
            path: "Things",
            name: "Things",
            components: {
                default: MytripThing
            },
            meta: {
                permission: "mytrip_things"
            }
        },
        {
            path: "Things/create",
            name: "Create Things",
            components: {
                default: MytripThingCreate
            },
            meta: {
                permission: "mytrip_things_create"
            }
        },
        {
            path: "Things/edit",
            name: "Edit Things",
            components: {
                default: MytripThingEdit
            },
            meta: {
                permission: "mytrip_things_update"
            }
        },
        {
            path: "Agency",
            name: "Agency",
            components: {
                default: MytripAgency
            },
            meta: {
                permission: "mytrip_agency"
            }
        },
        {
            path: "Agency/create",
            name: "Create Agency",
            components: {
                default: MytripAgencyCreate
            },
            meta: {
                permission: "mytrip_agency_create"
            }
        },
        {
            path: "Agency/edit",
            name: "Edit Agency",
            components: {
                default: MytripAgencyEdit
            },
            meta: {
                permission: "mytrip_agency_update"
            }
        },
        {
            path: "Operator",
            name: "Operator",
            components: {
                default: MytripOperator
            },
            meta: {
                permission: "mytrip_operator"
            }
        },
        {
            path: "Operator/create",
            name: "Create Operator",
            components: {
                default: MytripOperatorCreate
            },
            meta: {
                permission: "mytrip_operator_create"
            }
        },
        {
            path: "Operator/edit",
            name: "Edit Operator",
            components: {
                default: MytripOperatorEdit
            },
            meta: {
                permission: "mytrip_operator_update"
            }
        },
        {
            path: "Email",
            name: "Emails",
            components: {
                default: MytripEmail
            },
            meta: {
                permission: "mytrip_email"
            }
        }
    ]
};

let visa = {
    path: "/visa",
    redirect: "/visa",
    component: DashboardLayout,
    name: "Visa layout",
    children: [{
        path: "/",
        name: "Visa Dashboard",
        components: {
            default: Visa
        },
        meta: {
            permission: "visa"
        }
    }]
};

let hotelRequest = {
    path: "/hotelRequest",
    redirect: "/hotelRequest/dashboard",
    component: DashboardLayout,
    name: "hotelRequest layout",
    children: [{
            path: "dashboard",
            name: "hotelRequest Dashboard",
            components: {
                default: hotelRequestDashboard
            },
            meta: {
                permission: "hotelRequest"
            }
        },
        {
            path: "form",
            name: "hotelRequest Form",
            components: {
                default: hotelRequestForm
            },
            meta: {
                permission: "hotelRequest"
            }
        }
    ]
};

let Forms = {
    path: "/form",
    redirect: "/form",
    component: DashboardLayout,
    name: "form layout",
    children: [{
            path: "travel-insurance",
            name: "Travel insurance",
            components: {
                default: TravelInsuranceForm
            },
            meta: {
                permission: "Form"
            }
        },
        {
            path: "hotel-registration",
            name: "Hotel registration",
            components: {
                default: HotelRegistrationForm
            },
            meta: {
                permission: "Form"
            }
        }
    ]
};

let transfer = {
    path: "/transfer",
    redirect: "/transfer/dashboard",
    component: DashboardLayout,
    name: "Transfer layout",
    children: [{
            path: "Dashboard",
            name: "Transfer Dashboard",
            components: {
                default: Transfer
            },
            meta: {
                permission: "transfer_dashboard"
            }
        },
        {
            path: "information",
            name: "Transfer information",
            components: {
                default: TransferInformation
            },
            meta: {
                permission: "transfer_dashboard_formList_read"
            }
        }
    ]
};

let users = {
    path: "/user",
    redirect: "/user/dashboard",
    component: DashboardLayout,
    name: "Users layout",
    children: [{
        path: "Dashboard",
        name: "users information",
        components: {
            default: Users
        },
        meta: {
            permission: "users_table"
        }
    }]
};

// ##############  Flight  ##############
let flight = {
    path: "/flight",
    redirect: "/flight/booked/index",
    component: DashboardLayout,
    name: "Flight Layout",
    children: [{
            path: "booked/index",
            name: "FlightBookedIndex",
            components: {
                default: FlightBookedIndex
            },
            meta: {
                permission: "see flight"
            }
        },
        {
            path: "reserved/index",
            name: "FlightReservedIndex",
            components: {
                default: FlightReservedIndex
            },
            meta: {
                permission: "see flight"
            }
        },
        {
            path: "geo/index",
            name: "FlightGeoIndex",
            components: {
                default: FlightGeoIndex
            },
            meta: {
                permission: "see flight"
            }
        },
        {
            path: "stats/index",
            name: "FlightStatsIndex",
            components: {
                default: FlightStatsIndex
            },
            meta: {
                permission: "see flight"
            }
        },
        {
            path: "agents/index",
            name: "FlightAgentsIndex",
            components: {
                default: FlightAgentsIndex
            },
            meta: {
                permission: "see flight"
            }
        },
        {
            path: "discount-codes/index",
            name: "FlightDiscountCodesIndex",
            components: {
                default: FlightDiscountCodesIndex
            },
            meta: {
                permission: "see flight"
            }
        },
        {
            path: "travelers-info/:id",
            name: "FlightTravelersInfoIndex",
            components: {
                default: FlightTravelersInfoIndex
            },
            meta: {
                permission: "see flight"
            }
        },
        {
            path: "utm/index",
            name: "UTMIndex",
            components: {
                default: UTMIndex
            },
            meta: {
                permission: "see flight"
            }
        }
    ]
};
// ##############  /Flight  ##############

let Wordpress = {
    path: "/wordpress",
    redirect: "/wordpress",
    component: DashboardLayout,
    name: "Wordpress layout",
    children: [{
            path: "faq",
            name: "Wordpress FAQ",
            components: {
                default: WordpressFAQ
            },
            meta: {
                permission: "wordpress"
            }
        },
        {
            path: "ads",
            name: "Wordpress Ads",
            components: {
                default: WordpressAds
            },
            meta: {
                permission: "wordpress"
            }
        },
        {
            path: "hotel-rooms",
            name: "Wordpress Hotel Rooms",
            components: {
                default: WordpressHotelRooms
            },
            meta: {
                permission: "wordpress"
            }
        },
        {
            path: "hotel-verified-reviews",
            name: "Wordpress Hotel Verified Review",
            components: {
                default: WordpressHotelVerifiedReview
            },
            meta: {
                permission: "wordpress"
            }
        },
        {
            path: "hotel-search-location",
            name: "Wordpress Search Location",
            components: {
                default: WordpressSearchLocation
            },
            meta: {
                permission: "wordpress"
            }
        }
    ]
};

// ##############  Hotel  ##############
let hotels = {
    path: "/hotels",
    redirect: "/hotels",
    component: DashboardLayout,
    name: "hotel layout",
    children: [{
            path: "/",
            name: "Hotel Dashboard",
            components: {
                default: HotelsForm
            },
            meta: {
                permission: "Hotel"
            }
        },
        {
            path: "hotels/create",
            name: "Create Hotel",
            components: {
                default: HotelsCreateForm
            },
            meta: {
                permission: "Hotel"
            }
        },
        {
            path: "hotels/edit",
            name: "Edit Hotel",
            components: {
                default: HotelsEditForm
            },
            meta: {
                permission: "Hotel"
            }
        }
    ]
};
// ##############  Hotel  ##############

// ##############  Review  ##############
let review = {
    path: "/reviews",
    redirect: "/reviews",
    component: DashboardLayout,
    name: "review layout",
    children: [{
            path: "/reviews/dashboard",
            name: "Review Form",
            components: {
                default: ReviewForm
            },
            meta: {
                permission: "review"
            }
        },
        {
            path: "/reviews/answer",
            name: "Answer Form",
            components: {
                default: AnswerReviewForm
            },
            meta: {
                permission: "review"
            }
        },
        {
            path: "/reviews/hotel/:id",
            name: "Hotel Information Review",
            components: {
                default: InfoHotelReviewForm
            },
            meta: {
                permission: "review"
            },
            props: true
        },
        {
            path: "/reviews/import",
            name: "Review Import",
            components: {
                default: ImportFileReviewForm
            },
            meta: {
                permission: "review"
            }
        },
        {
            path: "/reviews/answer/:id",
            name: "Answer Review",
            components: {
                default: AnswerToReview
            },
            meta: {
                permission: "review"
            }
        }
    ]
};
// ##############  Review  ##############


// ##############  hashtag  ##############
let hashtag = {
    path: "/hashtag",
    redirect: "/hashtag",
    component: DashboardLayout,
    name: "hashtag layout",
    children: [{
            path: "/hashtag/dashboard",
            name: "Hashtag Form",
            components: {
                default: HashtagForm
            },
            meta: {
                permission: "hashtag"
            }
        },
        {
            path: "/hashtag/update/:name/:service",
            name: "Hashtag Update",
            components: {
                default: HashtagUpdate
            },
            meta: {
                permission: "hashtag"
            },
            props: true
        },
        {
            path: "/hashtag/store/",
            name: "Create Hashtag",
            components: {
                default: HashtagStore
            },
            meta: {
                permission: "hashtag"
            },
            props: true
        }
    ]
};
// ##############  hashtag  ##############


const routes = [{
        path: "/",
        redirect: "/dashboard",
        name: "Home"
    },
    authPages,
    mytrip,
    visa,
    transfer,
    users,
    flight,
    hotelRequest,
    Forms,
    Wordpress,
    hotels,
    review,
    hashtag,
    {
        path: "/",
        component: DashboardLayout,
        redirect: "/dashboard",
        name: "Dashboard layout",
        children: [{
            path: "dashboard",
            name: "Dashboard",
            components: {
                default: Dashboard
            },
            meta: {
                permission: "see dashboard"
            }
        }]
    },
    {
        path: "*",
        component: NotFound
    }
];

export default routes;