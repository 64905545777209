export default {
  inserted(elm, binding, vnode) {
    if (!window.localStorage.getItem('Apochi_permissions').includes(binding.value)){
      let arg = binding.arg ? binding.arg : 'child' ;
      if (arg === 'parent'){
        vnode.elm.parentElement.removeChild(vnode.elm)
      }else{
        vnode.elm.remove();
      }
    }
  }
};
