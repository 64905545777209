<template>
  <router-view></router-view>
</template>

<script>
export default {
  methods: {

  },
  mounted() {

  }
};
</script>
