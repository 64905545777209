import axios from "axios";
import Errors from './errors';
export default class forms{

    constructor(objects){
        this.objects = objects;
        for (let field in objects){
            this[field] = objects[field];
        }
        this.errors = new Errors();
    }

    has(field){
        return this.hasOwnProperty(field)
    }

    data(){
        for (let item in this.objects){
            this.objects[item] = this[item];
        }
        return this.objects;
    }

    clear(){
        for (let item in this.objects){
            this[item] = "";
        }
    }

    post(url){
        return this.submit('post',url);
    }

    delete(url){
        return this.submit('delete',url);
    }

    update(url){
        return this.submit('update',url);
    }

    submit(submitType,url){
        return new Promise((resolve, reject) => {
            axios[submitType](url,this.data())
                .then((response)=>{
                    resolve(response.data);
                })
                .catch((response)=>{
                    reject(response);
                })
        })
    }
}
