import Vue from 'vue';
import FormClass from './forms';
import axios from 'axios';
import router from '../routes/router';

export default class extends FormClass{

    /**
     * get object from vye components
     * */
    constructor(objects){
        super(objects);
    }

    /**
    * login user with this method call parent post.
    * */
    login(lock = false){
      return new Promise((resolve, reject) => {
        this.post(Vue.prototype.loginURL)
          .then((response) => {
            this.successLogin(response);
            resolve();
          })
          .catch(() => {
            lock !== true ? this.faildLogin() : null ;
            reject();
          })
      });
    }

    /**
     * lock screen user
    **/
    lockScreen(){
      let permissions = [
        'login',
        'Lock screen'
      ];
      window.localStorage.removeItem("Apochi_accessToken");
      window.localStorage.setItem("Apochi_permissions", JSON.stringify(permissions));
      this.clear();
      router.push({ name: 'Lock' })
    }

    /**
     * check user for login status
     * loginStatus return a true or false
     * */
    check(){
        this.checkAccessToken().then((response)=>{
            window.localStorage.setItem("Apochi_UserInformation", JSON.stringify(response));
        }).catch(()=>{
            window.location.href = Vue.prototype.redirectURL;
        })
    }

    /**
     * check user has this permission or not
     * */
    hasPermission(permission){
        let permissions = window.localStorage.getItem('Apochi_permissions');
        return permissions.includes(permission);
    }
    /**
     * check user has this permission or not
     * */
    getAllPermissions(){
        return window.localStorage.getItem("Apochi_permissions");
    }

    /**
     * after successfully login this method called by login method
     **/
    successLogin(response) {
        window.localStorage.setItem("Apochi_accessToken",response.access_token);
        window.localStorage.setItem("Apochi_permissions", JSON.stringify(response.permissions));
        this.clear();
    }

    /**
     * if login fails....
     **/
    faildLogin(){
        this.clear();
        this.clearAllInformations();
        window.location.href = Vue.prototype.redirectURL;
    }
    /**
     * if login fails....
     **/
    clearAllInformations(){
      window.localStorage.removeItem("Apochi_accessToken");
      window.localStorage.setItem("Apochi_permissions",JSON.stringify(['login']));
      window.localStorage.removeItem("Apochi_UserInformation");
    }

    /**
     * check access token for user
     **/
    checkAccessToken() {
        let config = {headers: {'Authorization': "bearer " + window.localStorage.getItem("Apochi_accessToken")}};
        let bodyParameters = {};
        return new Promise((resolve, reject) => {
            axios.post(Vue.prototype.checkUserURL, bodyParameters, config)
                .then((response) => {
                    resolve(response);
                })
                .catch(() => {
                    this.faildLogin();
                    reject();
                });
        });
    }

    getHeader(){
      return {
        headers : {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + window.localStorage.getItem('Apochi_accessToken'),
        }
      }
    }
}
