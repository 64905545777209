<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#pablo">{{ routeName }}</a>
    </div>

    <ul class="navbar-nav ml-auto">
<!--      <modal-->
<!--        :show.sync="searchModalVisible"-->
<!--        class="modal-search"-->
<!--        id="searchModal"-->
<!--        :centered="false"-->
<!--        :show-close="true"-->
<!--      >-->
<!--        <input-->
<!--          slot="header"-->
<!--          v-model="searchQuery"-->
<!--          type="text"-->
<!--          class="form-control"-->
<!--          id="inlineFormInputGroup"-->
<!--          placeholder="SEARCH"-->
<!--        />-->
<!--      </modal>-->
<!--      <base-dropdown-->
<!--        tag="li"-->
<!--        :menu-on-right="true"-->
<!--        title-tag="a"-->
<!--        title-classes="nav-link"-->
<!--        class="nav-item"-->
<!--      >-->
<!--        <template-->
<!--          slot="title"-->
<!--        >-->
<!--          <div class="notification d-none d-lg-block d-xl-block"></div>-->
<!--          <i class="tim-icons icon-sound-wave"></i>-->
<!--          <p class="d-lg-none">New Notifications</p>-->
<!--        </template>-->
<!--        <li class="nav-link">-->
<!--          <a href="#" class="nav-item dropdown-item"-->
<!--            >Mike John responded to your email</a-->
<!--          >-->
<!--        </li>-->
<!--        <li class="nav-link">-->
<!--          <a href="#" class="nav-item dropdown-item">You have 5 more tasks</a>-->
<!--        </li>-->
<!--        <li class="nav-link">-->
<!--          <a href="#" class="nav-item dropdown-item"-->
<!--            >Your friend Michael is in town</a-->
<!--          >-->
<!--        </li>-->
<!--        <li class="nav-link">-->
<!--          <a href="#" class="nav-item dropdown-item">Another notification</a>-->
<!--        </li>-->
<!--        <li class="nav-link">-->
<!--          <a href="#" class="nav-item dropdown-item">Another one</a>-->
<!--        </li>-->
<!--      </base-dropdown>-->
      <base-dropdown
        tag="li"
        :menu-on-right="true"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template
          slot="title"
        ><span v-text="user.data.name" class="p-4"></span>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none">Log out</p>
        </template>
        <li class="nav-link">
          <a class="nav-item dropdown-item" @click.prevent="lockScreen">Lock Screen</a>
        </li>
        <li class="nav-link">
          <a class="nav-item dropdown-item" @click.prevent="logOut">Log out</a>
        </li>
      </base-dropdown>
    </ul>

  </base-nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';

export default {
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal
  },
  computed: {
      routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
        user:{
            "data":{
                "id":999,
                "name":"",
                "email":"",
                "email_verified_at":null,
                "created_at":"2100-12-12 13:36:01",
                "updated_at":"2100-12-12 13:36:01"
            }
        },
        activeNotifications: false,
        showMenu: false,
        searchModalVisible: false,
        searchQuery: '',
    };
  },
    mounted(){
      this.userInfo();
    },
  methods: {
      lockScreen(){
          window.User.prototype.lockScreen();
      },
      userInfo(){
          setTimeout(()=>{
              this.user = JSON.parse(window.localStorage.getItem('Apochi_UserInformation'))
          },6000);
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      toggleNotificationDropDown() {
        this.activeNotifications = !this.activeNotifications;
      },
      closeDropDown() {
        this.activeNotifications = false;
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
      },
      hideSidebar() {
        this.$sidebar.displaySidebar(false);
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      logOut(){
          window.User.prototype.faildLogin();
      }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
