import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state:{
        flight: {
            utm: {
                orgItems: [],
                items: [],
                sources: [],
                campaigns: [],
                mediums: [],
                contents: [],
            },
            sourcesCounter: [],
        }
    },


    mutations:{
        setOrgUTMItems(state, utms){
            state.flight.utm.orgItems = utms;
        },

        setUTMItems(state, utms){
            state.flight.utm.items = utms;
        },

        setUTMFilterItems(state, [sources, campaigns, mediums, contents]){
            state.flight.utm.sources = sources;
            state.flight.utm.campaigns = campaigns;
            state.flight.utm.mediums = mediums;
            state.flight.utm.contents = contents;
        },

        setUTMSourcesCounter(state, sources){
            state.flight.sourcesCounter = sources;
        },
    },


    actions:{
        setOrgUTMItemsAction: (context, utms) => context.commit('setOrgUTMItems', utms),
        setUTMItemsAction: (context, utms) => context.commit('setUTMItems', utms),
        setUTMFilterItemsAction: (context, [sources, campaigns, mediums, contents]) => context.commit('setUTMFilterItems', [sources, campaigns, mediums, contents]),
        setUTMSourcesCounterAction: (context, sources) => context.commit('setUTMSourcesCounter', sources),
    },


    getters:{
        getOrgUTMItems: (state) => state.flight.utm.orgItems,
        getUTMItems: (state) => state.flight.utm.items,
        getUTMFilterItems(state){
            return {
                'sources': state.flight.utm.sources,
                'campaigns': state.flight.utm.campaigns,
                'mediums': state.flight.utm.mediums,
                'contents': state.flight.utm.contents,
            }
        },
        getSourcesCounter: (state) => state.flight.sourcesCounter,
    }

})