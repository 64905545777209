import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import axios from 'axios'
import VueAxios from 'vue-axios'
// router setup
import router from './routes/router';
import i18n from './i18n';
import './registerServiceWorker'
// users
import User from './forms/user';
// Vuex
import store from './store';
// Export Excel
import JsonExcel from 'vue-json-excel'

//URLS
Vue.prototype.websiteURL    = process.env.VUE_APP_URL+'/';
Vue.prototype.websiteURLWithOutApi    = process.env.VUE_APP_URL;
Vue.prototype.redirectURL   = process.env.VUE_APP_FRONT_URL;
Vue.prototype.loginURL      = process.env.VUE_APP_URL+'/login';
Vue.prototype.checkUserURL  = process.env.VUE_APP_URL+'/me';

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(VueAxios, axios);
Vue.component('downloadExcel', JsonExcel)

//User Class
window.User = User;
window.localStorage.getItem("Apochi_accessToken") ? null : window.localStorage.setItem("Apochi_accessToken","");
window.localStorage.getItem("Apochi_permissions") ? null : window.localStorage.setItem("Apochi_permissions", JSON.stringify(['login']));

// interceptors
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // 401,403 go to login
  if (error.response.status === 403){
    User.prototype.check();
  }
  if (error.response.status === 500 ){
    User.prototype.check();
  }
  return Promise.reject(error);
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  i18n,
  store
});
