export default class errors{
    constructor(){
        this.errors = {};
    }
    get(field){
        if (typeof this.errors[field] !== 'string'){
            return this.errors[field][0];
        }
        return this.errors[field];
    }
    record(error){
        this.errors= error;
    }
    target(name){
        delete this.errors[name];
    }
    has(field){
        return this.errors.hasOwnProperty(field)
    }
    length(){
        return Object.keys(this.errors).length > 0 ;
    }
}
