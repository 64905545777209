import VueRouter from 'vue-router';
import routes from './routes';

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  // eslint-disable-next-line no-undef
  if ((window.localStorage.getItem("Apochi_permissions").includes(to.meta.permission))){
    next();
  }else{
    if ((window.localStorage.getItem("Apochi_permissions").includes('login'))){
      next('/login');
    }
    next(false);
  }
});
export default router;
