var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t('sidebar.dashboard'),
                    icon: 'tim-icons icon-chart-pie-36',
                    path: '/dashboard'
                }}}),_c('sidebar-item',{directives:[{name:"hasPermission",rawName:"v-hasPermission",value:("see my trip"),expression:"`see my trip`"}],attrs:{"link":{
                    name: _vm.$t('sidebar.mytrip'),
                    icon: 'tim-icons icon-bus-front-12'
                }}},[_c('sidebar-item',{directives:[{name:"hasPermission",rawName:"v-hasPermission",value:("mytrip_dashboard"),expression:"`mytrip_dashboard`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.mytripDashboard'),
                        path: '/mytrip/Dashboard'
                    }}}),_c('sidebar-item',{directives:[{name:"hasPermission",rawName:"v-hasPermission",value:("mytrip_things"),expression:"`mytrip_things`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.mytripThings'),
                        path: '/mytrip/Things'
                    }}}),_c('sidebar-item',{directives:[{name:"hasPermission",rawName:"v-hasPermission",value:("mytrip_agency"),expression:"`mytrip_agency`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.mytripAgency'),
                        path: '/mytrip/Agency'
                    }}}),_c('sidebar-item',{directives:[{name:"hasPermission",rawName:"v-hasPermission",value:("mytrip_operator"),expression:"`mytrip_operator`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.mytripOperator'),
                        path: '/mytrip/Operator'
                    }}}),_c('sidebar-item',{directives:[{name:"hasPermission",rawName:"v-hasPermission",value:("mytrip_email"),expression:"`mytrip_email`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.mytripEmail'),
                        path: '/mytrip/Email'
                    }}})],1),_c('sidebar-item',{directives:[{name:"hasPermission",rawName:"v-hasPermission",value:("visa"),expression:"`visa`"}],attrs:{"link":{
                    name: _vm.$t('sidebar.visa'),
                    icon: 'tim-icons icon-credit-card'
                }}},[_c('sidebar-item',{attrs:{"link":{
                        name: _vm.$t('sidebar.visaDashboard'),
                        path: '/visa'
                    }}})],1),_c('sidebar-item',{directives:[{name:"hasPermission",rawName:"v-hasPermission",value:("Hotel"),expression:"`Hotel`"}],attrs:{"link":{
                    name: _vm.$t('sidebar.hotel'),
                    icon: 'tim-icons icon-credit-card'
                }}},[_c('sidebar-item',{attrs:{"link":{
                        name: _vm.$t('sidebar.hotelDashboard'),
                        path: '/hotels'
                    }}})],1),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("see transfer"),expression:"`see transfer`"}],attrs:{"link":{
                    name: _vm.$t('sidebar.Transfer'),
                    icon: 'tim-icons icon-square-pin'
                }}},[_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("transfer_dashboard"),expression:"`transfer_dashboard`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.dashboard'),
                        path: '/transfer'
                    }}})],1),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("see flight"),expression:"`see flight`"}],attrs:{"link":{
                    name: _vm.$t('sidebar.flight'),
                    icon: 'tim-icons icon-world'
                }}},[_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("see flight"),expression:"`see flight`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.flightBookedIndex'),
                        path: '/flight/booked/index'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("see flight"),expression:"`see flight`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.flightReservedIndex'),
                        path: '/flight/reserved/index'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("see flight"),expression:"`see flight`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.flightGeoIndex'),
                        path: '/flight/geo/index'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("see flight"),expression:"`see flight`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.flightStatsIndex'),
                        path: '/flight/stats/index'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("see flight"),expression:"`see flight`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.flightAgentsIndex'),
                        path: '/flight/agents/index'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("see flight"),expression:"`see flight`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.flightDiscountCodesIndex'),
                        path: '/flight/discount-codes/index'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("see flight"),expression:"`see flight`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.flightUTMIndex'),
                        path: '/flight/utm/index'
                    }}})],1),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("hotelRequest"),expression:"`hotelRequest`"}],attrs:{"link":{
                    name: _vm.$t('sidebar.hotelRequest'),
                    icon: 'tim-icons icon-badge'
                }}},[_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("hotelRequest"),expression:"`hotelRequest`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.hotelRequest'),
                        path: '/hotelRequest/dashboard'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("hotelRequest"),expression:"`hotelRequest`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.hotelRequestForm'),
                        path: '/hotelRequest/form'
                    }}})],1),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("Form"),expression:"`Form`"}],attrs:{"link":{
                    name: _vm.$t('sidebar.form'),
                    icon: 'tim-icons icon-badge'
                }}},[_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("Form"),expression:"`Form`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.travelInsurance'),
                        path: '/form/travel-insurance'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("Form"),expression:"`Form`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.hotelRegistration'),
                        path: '/form/hotel-registration'
                    }}})],1),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("wordpress"),expression:"`wordpress`"}],attrs:{"link":{
                    name: _vm.$t('sidebar.wordpress'),
                    icon: 'tim-icons icon-attach-87'
                }}},[_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("wordpress"),expression:"`wordpress`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.wordpressFaq'),
                        path: '/wordpress/faq'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("wordpress"),expression:"`wordpress`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.wordpressSearchLocation'),
                        path: '/wordpress/hotel-search-location'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("wordpress"),expression:"`wordpress`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.wordpressAds'),
                        path: '/wordpress/ads'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("wordpress"),expression:"`wordpress`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.wordpressHotelRoom'),
                        path: '/wordpress/hotel-rooms'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("wordpress"),expression:"`wordpress`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.wordpressHotelVerifiedReview'),
                        path: '/wordpress/hotel-verified-reviews'
                    }}})],1),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("review"),expression:"`review`"}],attrs:{"link":{
                    name: _vm.$t('sidebar.reviews'),
                    icon: 'tim-icons icon-notes'
                }}},[_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("review"),expression:"`review`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.review'),
                        path: '/reviews/dashboard'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("review"),expression:"`review`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.answer'),
                        path: '/reviews/answer'
                    }}}),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("review"),expression:"`review`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.reviewimportfile'),
                        path: '/reviews/import'
                    }}})],1),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("hashtag"),expression:"`hashtag`"}],attrs:{"link":{
                    name: _vm.$t('sidebar.hashtag'),
                    icon: 'tim-icons icon-tag'
                }}},[_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("hashtag"),expression:"`hashtag`"}],attrs:{"link":{
                        name: _vm.$t('sidebar.hashtag'),
                        path: '/hashtag/dashboard'
                    }}})],1),_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("users"),expression:"`users`"}],attrs:{"link":{
                    name: _vm.$t('sidebar.user'),
                    icon: 'tim-icons icon-single-02'
                }}},[_c('sidebar-item',{directives:[{name:"has-permission",rawName:"v-has-permission",value:("users_table"),expression:"`users_table`"}],attrs:{"link":{ name: _vm.$t('sidebar.users'), path: '/user' }}})],1)]}}])}),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }