import clickOutside from 'src/directives/click-ouside.js';
import hasPermission from 'src/directives/permission-check.js';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('click-outside', clickOutside);
    Vue.directive('hasPermission', hasPermission);
  }
};

export default GlobalDirectives;
